/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 *
 * Google CDN, Latest jQuery
 * To use the default WordPress version of jQuery, go to lib/config.php and
 * remove or comment out: add_theme_support('jquery-cdn');
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

        // var maxHeight = -1;
        // $('.cracker-listing ul li.cracker .description').each(function() {
        //    maxHeight = maxHeight > $(this).height() ? maxHeight : $(this).height();
        //  });

        //  $('.cracker-listing ul li.cracker .description').each(function() {
        //    $(this).height(maxHeight);
        //  });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        $('a.arrow-down').smoothScroll({
          offset: -90,
        });

        $('.slick-slideshow ul.slides').slick({
          dots: false,
          arrows: false,
          speed: 500,
          autoplay: true,
          fade: true
        });

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  function resizeScroll() {
    $(window).trigger('resize').trigger('scroll');
  }

  $('.left-sidebar .widget h3').click(function(){
    $(this).parent().toggleClass('collapsed');
    $(window).trigger('resize');
  })
  $('.comments a.toggle, .comment-reply-link').click(function(event){
    event.preventDefault();
    $('#commentform').toggleClass('active');
    resizeScroll();
  })

  var viewportWidth = $(window).width();
  $(window).scroll(function () {
    if (viewportWidth > 767) {
      if ($(window).scrollTop() > 0) {
        $('body').addClass('header-fixed');
      }
      else {
        $('body').removeClass('header-fixed');
      }
    }
  });

  function moveSearchBox() {
    var searchBox = $('#map_search').html();
    $('.where-to-buy .page-header').append('<div id="map-search-box"></div>').find('#map-search-box').append(searchBox);
    $('.where-to-buy .location_search_zip_cell input').attr('placeholder','Enter your zip code');
  }
  moveSearchBox();

})(jQuery); // Fully reference jQuery after this point.
